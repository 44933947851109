import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "name": "Not Found",
  "title": "Oops, page not found",
  "description": "We could not find this page"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const NotFoundPage = makeShortcode("NotFoundPage");
const SectionHeaderImage = makeShortcode("SectionHeaderImage");
const Paragraph = makeShortcode("Paragraph");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <NotFoundPage mdxType="NotFoundPage">
	Ummm, looks like we’re lost!
	<SectionHeaderImage src={'/cloudinary/sphere/image/upload/v1627402222/website/404-sticker.svg'} mdxType="SectionHeaderImage" />
	<Paragraph mdxType="Paragraph" />
	Is this the page you’re looking for?
    </NotFoundPage>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      